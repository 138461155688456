import { apiRequest } from "./Api";

export const getAllowedUsers = async () => {
  return apiRequest<any, any>("get", "allowed_users/");
};

export const updateAllowedUsers = async (email: string) => {
  return apiRequest<any, any>("post", `add_allowed_user/?email=${email}`);
};

export const deleteAllowedUsers = async (email: string) => {
  return apiRequest<any, any>("delete", `remove_allowed_user/?email=${email}`);
};
