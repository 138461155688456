import { apiRequest } from "./Api";

export const register = async (data: any) => {
  return apiRequest<any, any>("post", "token/", data);
};

export const getOrganizations = async () => {
  return apiRequest<any, any>("get", "organizations/");
};

export const getSalesAnalyticsByOrganization = async (
  organizationId: string,
  layout: string,
  year: string,
  month: string | null
) => {
  return apiRequest<any, any>(
    "get",
    `analytics/${organizationId}/${layout}/?year=${year}${
      month && `&month=${month}`
    }`
  );
};
