import Axios from "axios";

export async function apiRequest<D = {}, R = unknown>(
  method: "get" | "delete" | "head" | "options" | "post" | "put" | "patch",
  path: string,
  input?: D,
  auth?: any
) {
  let accessToken = localStorage.getItem("access_token")!!;

  const res = await Axios.request<R>({
    url: `${process.env.REACT_APP_API_URL}/${path}`,
    method: method,
    data: input,
    auth: auth,
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  });

  return res;
}

export * from "./User";
export * from "./Whitelist";
