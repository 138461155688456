import React, { useRef, useState } from "react";

import { useOutsideClickDetection } from "../../../lib/hooks/useOutsideClickDetection";
import { useResponsiveDimensions } from "../../../lib/hooks/useResponsiveDimensions";
import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";

import "./HeaderProfile.scss";
import { Icon } from "../../shared/Icon/Icon";

const HeaderProfile = () => {
  const [isOpen, setIsOpen] = useState(false);
  const headerProfileRef = useRef(null);

  const isMobile = useResponsiveDimensions().isMobile;
  const authCtx = useAuthContext();

  const userEmail = localStorage.getItem("email");

  const handleOutSideClick = () => {
    setIsOpen(false);
  };

  useOutsideClickDetection(headerProfileRef, handleOutSideClick);

  return (
    <div className="HeaderProfile" ref={headerProfileRef}>
      <div
        className="HeaderProfile__head"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <div className="HeaderProfile__info">
          <p className="HeaderProfile__role">
            {authCtx.user ? authCtx.user : userEmail}
          </p>
        </div>
      </div>
      {isOpen && (
        <div className="HeaderProfile__body">
          <div
            className="HeaderProfile__logout"
            onClick={() => authCtx.logOut()}
          >
            <Icon
              onClick={() => {}}
              className="HeaderProfile__icon"
              icon="logout"
            />
            <span>Log Out</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderProfile;
