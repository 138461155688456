import React from "react";

import bouncerLogo from "../../../assets/images/bouncer.svg";
import { Link } from "react-router-dom";

import "./BouncerContainer.scss";
import FlickitySlider from "../FlickitySlider/FlickitySlider.js";
import { Icon } from "../Icon/Icon";

const BouncerContainer = () => {
  return (
    <div className="BouncerContainer">
      <div className="BouncerContainer__logo-wrapper">
        <Link to="/" className="Login__logo-link">
          <Icon className="BouncerContainer__logo" icon="bouncer" />
        </Link>
      </div>
      <Icon className="BouncerContainer__fingerprint" icon="fingerprint-logo" />

      <div className="BouncerContainer__slider">
        <FlickitySlider
          options={{
            lazyLoad: true,
            wrapAround: true,
            prevNextButtons: false,
            autoPlay: true,
          }}
        >
          <div className="BouncerContainer__slide">
            <h2 className="BouncerContainer__slide-title">
              Empowering Digital Identity Verification
            </h2>
            <p className="BouncerContainer__slide-description">
              Bouncer is a global, remote identity verification service for
              businesses that needs to verify customers in their on boarding
              process.
            </p>
          </div>
        </FlickitySlider>
      </div>
    </div>
  );
};

export default BouncerContainer;
