import React, { useEffect, useState } from "react";
import { AuthContext, AuthContextValuesType } from "./AuthContext";
import * as API from "../../../api/Api";
import { useNavigate, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";

interface AuthContextProviderProps {
  children: React.ReactNode | null;
}

export const AuthContextProvider = (props: AuthContextProviderProps) => {
  const [userError, setUserError] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("access_token") ? true : false
  );
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState<any>({
    type: "info",
    title: "",
    subtitle: "",
  });
  const [user, setUser] = useState(
    localStorage.getItem("email") ? localStorage.getItem("email") : ""
  );

  const navigate = useNavigate();
  const location = useLocation();

  //checking if refresh token has expired, if true, logout user
  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");

    if (accessToken !== null) {
      const tokenInfo: any = jwt_decode(accessToken);
      const isExpired = dayjs.unix(tokenInfo.exp).diff(dayjs()) < 1;
      if (isExpired) {
        localStorage.clear();
        window.location.reload();
        setUser("");
      }
    }
  }, [location, user]);

  //login
  const handleLogin = async (data: any) => {
    setIsLoading(true);
    API.register(data)
      .then((res) => {
        if (res.data.access_token) {
          localStorage.setItem("access_token", res.data.access_token);
          const tokenInfo: any = jwt_decode(res.data.access_token);
          localStorage.setItem("email", tokenInfo?.sub);
          localStorage.setItem("localStorageAuth", "true");
          setIsLoading(false);
          setIsAuthenticated(true);
          navigate("/");
        }
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          setMessage({
            type: "error",
            title: err?.response?.data?.detail,
          });
        }
        setIsLoading(false);
      });
  };

  //logout
  const logOut = () => {
    localStorage.clear();
    setIsAuthenticated(false);
    setMessage({
      type: "info",
      title: "",
      subtitle: "",
    });
  };

  const context: AuthContextValuesType = {
    userError,
    isAuthenticated,
    handleLogin,
    logOut,
    isLoading,
    message,
    user,
  };

  return (
    <AuthContext.Provider value={context}>
      {props.children}
    </AuthContext.Provider>
  );
};
