import React, { useEffect, useState } from "react";
import * as API from "../../../api/Api";
import { Icon } from "../../../components/shared/Icon/Icon";
import cs from "classnames";
import SelectBox from "../../../components/shared/SelectBox/SelectBox";
import { CSVLink } from "react-csv";

import "./OrganizationAnalytics.scss";

const OrganizationAnalytics = () => {
  const d = new Date();
  const currentYear = d.getFullYear();

  const arrayOfYears = Array.from(Array(23), (_, index) => ({
    name: `${currentYear - index}`,
    value: `${currentYear - index}`,
  }));

  const [organizations, setOrganizations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [layout, setLayout] = useState("web");
  const [selectedYearFilter, setSelectedYearFilter] = useState({
    name: `${arrayOfYears[0].value}`,
    value: `${arrayOfYears[0].value}`,
  });
  const [selectedMonthFilter, setSelectedMonthFilter] = useState({
    name: "Month",
    value: "",
  });
  const [selectedTime, setSelectedTime] = useState("Year");
  const [count, setCount] = useState<any>(0);
  const [sortMode, setSortMode] = useState("asc");
  const [dataForCsv, setDataForCsv] = useState<any>([]);

  const selectedOrganization = JSON.parse(
    localStorage.getItem("selectedOrganization")!!
  );

  const monthsOfYears = [
    { name: "Jan", value: "1" },
    { name: "Feb", value: "2" },
    { name: "Mar", value: "3" },
    { name: "Apr", value: "4" },
    { name: "May", value: "5" },
    { name: "Jun", value: "6" },
    { name: "Jul", value: "7" },
    { name: "Aug", value: "8" },
    { name: "Sep", value: "9" },
    { name: "Oct", value: "10" },
    { name: "Nov", value: "11" },
    { name: "Dec", value: "12" },
  ];

  const getAllDevices = () => {
    setIsLoading(true);
    setDataForCsv([]);
    API.getSalesAnalyticsByOrganization(
      selectedOrganization?.id,
      layout,
      selectedYearFilter?.value,
      selectedMonthFilter?.value
    )
      .then((res) => {
        let filteredOrganizations: any = Object.entries(res.data);
        setOrganizations(filteredOrganizations);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAllDevices();
  }, [layout, selectedYearFilter?.value, selectedMonthFilter?.value]);

  useEffect(() => {
    if (organizations.length !== 0) {
      let sum = 0;
      organizations.map((item: any) => {
        sum = sum + item[1]?.count;
      });
      setCount(sum);
    }
  }, [layout, organizations]);

  useEffect(() => {
    if (organizations.length !== 0) {
      organizations.map((item: any) => {
        const data = {
          id_of_user: item[0],
          number_of_scans: item[1]?.count,
          success_rate: `${item[1]?.success_percentage} %`,
        };

        if (
          dataForCsv.filter((x: any) => x?.id_of_user === data.id_of_user)
            .length === 0
        ) {
          setDataForCsv((prev: any) => [...prev].concat(data));
        }
      });
    }
  }, [organizations, layout]);

  const sortColumnBy = (mode: string) => {
    if (sortMode === "asc" && mode === "id") {
      let sorted = [...organizations].sort((a: any, b: any) =>
        a[0].toLowerCase() < b[0].toLowerCase() ? -1 : 1
      );
      setOrganizations(sorted);
    }
    if (sortMode === "asc" && mode !== "id") {
      let sorted: any = [...organizations].sort(
        (a: any, b: any) => a[1][mode] - b[1][mode]
      );
      setOrganizations(sorted);
    }
    if (sortMode === "desc" && mode !== "id") {
      let sorted: any = [...organizations].sort(
        (a: any, b: any) => b[1][mode] - a[1][mode]
      );
      setOrganizations(sorted);
    }
    if (sortMode === "desc" && mode === "id") {
      let sorted: any = [...organizations].sort((a: any, b: any) => {
        if (a[0].toLowerCase() > b[0].toLowerCase()) {
          return -1;
        }
        if (b[0].toLowerCase() > a[0].toLowerCase()) {
          return 1;
        }
        return 0;
      });

      setOrganizations(sorted);
    }
  };

  const csvHeaders = [
    { label: "ID of User", key: "id_of_user" },
    { label: "No. of Scans", key: "number_of_scans" },
    { label: "Success Rate", key: "success_rate" },
  ];

  return (
    <div className="OrganizationAnalytics">
      <div className="OrganizationAnalytics__header">
        <div className="OrganizationAnalytics__title">
          Analytics <span style={{ fontWeight: 400 }}>&gt;</span>{" "}
          {selectedOrganization?.name}
        </div>
        <div className="OrganizationAnalytics__header-layout">
          <div
            className={cs(
              "OrganizationAnalytics__layout-item",
              layout === "device" &&
                "OrganizationAnalytics__layout-item--active"
            )}
            onClick={() => {
              setLayout("device");
            }}
          >
            Devices
          </div>
          <div
            className={cs(
              "OrganizationAnalytics__layout-item",
              layout === "web" && "OrganizationAnalytics__layout-item--active"
            )}
            onClick={() => {
              setLayout("web");
            }}
          >
            Web
          </div>
        </div>
      </div>

      <div className="OrganizationAnalytics__container">
        <div className="OrganizationAnalytics__search-wrapper">
          <div className="OrganizationAnalytics__header-buttons">
            <span className="OrganizationAnalytics__header-text">View by:</span>
            <div className="flex">
              <SelectBox
                className="OrganizationAnalytics__selectbox3"
                className2="OrganizationAnalytics__selectbox-header"
                items={arrayOfYears}
                selectedItem={selectedYearFilter}
                onItemSelect={(name: any, value: any) => {
                  setSelectedYearFilter({ name: name, value: value });
                  setSelectedMonthFilter({ name: "Month", value: "" });
                  setSelectedTime("Year");
                }}
                isLoading={isLoading && selectedTime === "Year"}
                active
              />

              <SelectBox
                isLoading={isLoading && selectedTime === "Month"}
                className="OrganizationAnalytics__selectbox2"
                className2="OrganizationAnalytics__selectbox-header"
                items={monthsOfYears}
                selectedItem={selectedMonthFilter}
                onItemSelect={(name, value) => {
                  setSelectedMonthFilter({ name: name, value: value });
                  setSelectedTime("Month");
                }}
                active={selectedMonthFilter?.value !== ""}
              />
            </div>
          </div>
          <CSVLink
            data={dataForCsv}
            headers={csvHeaders}
            filename={`${selectedOrganization?.name}_analytics.csv`}
            className="Button OrganizationAnalytics__export-button"
            target="_blank"
          >
            Export to csv
          </CSVLink>
        </div>
        {isLoading ? (
          <div className="page-spinner" />
        ) : (
          <>
            {organizations?.length !== 0 ? (
              <div className="OrganizationAnalytics__grid">
                <div className="OrganizationAnalytics__log-header">
                  <div>
                    <span>ID of User</span>
                    <Icon
                      icon="arrows-down-up"
                      className="OrganizationAnalytics__header-icon"
                      onClick={() => {
                        sortColumnBy("id");
                        setSortMode(sortMode === "asc" ? "desc" : "asc");
                      }}
                    />
                  </div>
                  <div>
                    <span>No. of Scans</span>
                    <Icon
                      icon="arrows-down-up"
                      className="OrganizationAnalytics__header-icon"
                      onClick={() => {
                        sortColumnBy("count");
                        setSortMode(sortMode === "asc" ? "desc" : "asc");
                      }}
                    />
                  </div>
                  <div>
                    <span>Success Rate</span>
                    <Icon
                      icon="arrows-down-up"
                      className="OrganizationAnalytics__header-icon"
                      onClick={() => {
                        sortColumnBy("success_percentage");
                        setSortMode(sortMode === "asc" ? "desc" : "asc");
                      }}
                    />
                  </div>
                </div>

                {organizations?.map((item: any, index: number) => (
                  <div
                    className="OrganizationAnalytics__organization"
                    key={index}
                  >
                    <div className="OrganizationAnalytics__organization-name">
                      {item[0]}
                    </div>

                    <div className="OrganizationAnalytics__organization-category">
                      {item[1]?.count}
                    </div>
                    <div className="OrganizationAnalytics__organization-address">
                      {item[1]?.success_percentage} %
                    </div>
                  </div>
                ))}

                <div className="OrganizationAnalytics__count-container">
                  <div>
                    <span>Total: {organizations?.length}</span>{" "}
                  </div>
                  <div className="OrganizationAnalytics__count-sum">
                    {" "}
                    Sum: {count}
                  </div>
                </div>
              </div>
            ) : (
              <div className="Analytics__no-content"> No user found! </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default OrganizationAnalytics;
