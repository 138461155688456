import React from "react";
import BouncerContainer from "../../components/shared/BouncerContainer/BouncerContainer";
import LoginForm from "./LoginForm/LoginForm";
import { useResponsiveDimensions } from "../../lib/hooks/useResponsiveDimensions";
import { Link } from "react-router-dom";
import bouncerLogo2 from "../../assets/images/bouncer-logo2.svg";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";
import AuthAlert from "../../components/shared/AuthAlert/AuthAlert";

import "./Login.scss";

const Login = () => {
  const isTablet = useResponsiveDimensions().isTablet;
  const navigate = useNavigate();

  const authCtx = useAuthContext();

  return (
    <div className="Login">
      {!isTablet && <BouncerContainer />}
      <div className="Login__container">
        {isTablet && (
          <div className="Login__logo-wrapper">
            <Link to="/" className="Login__logo-link">
              <img
                src={bouncerLogo2}
                alt=""
                height="56"
                className="Login__logo"
              />
            </Link>
          </div>
        )}

        {authCtx.message?.title && (
          <AuthAlert
            title={authCtx.message?.title}
            type={authCtx.message?.type}
            subtitle={authCtx.message?.subtitle}
          />
        )}

        <div className="Login__wrapper">
          <h1 className="Login__title">Log in to your account</h1>
          {isTablet && (
            <p className="Login__subtitle">Enter your account information.</p>
          )}
          <LoginForm />
        </div>
      </div>
    </div>
  );
};

export default Login;
