import Routes from "./routes/Routes";
import { AuthContextProvider } from "./lib/context/AuthContext/AuthContextProvider";
import { SideBarContextProvider } from "./lib/context/SidebarContext/SidebarContextProvider";

import "./App.scss";

function App() {
  return (
    <div className="App">
      <SideBarContextProvider>
        <AuthContextProvider>
          <Routes />
        </AuthContextProvider>
      </SideBarContextProvider>
    </div>
  );
}

export default App;
