import React from "react";

import { Navigate, Outlet } from "react-router-dom";
import Layout from "../layout/Layout";

interface PrivateRouteProps {
  isAuth: any;
  localStorageAuth: any;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { isAuth, localStorageAuth } = props;

  return (
    <>
      {isAuth && localStorageAuth === "true" ? (
        <Layout>
          <Outlet />
        </Layout>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
};

export default PrivateRoute;
