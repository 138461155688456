import React from "react";
import { ReactNode } from "react";
import Login from "../pages/Login/Login";
import Analytics from "../pages/Analytics/Analytics";
import Whitelist from "../pages/Whitelist/Whitelist";
import OrganizationAnalytics from "../pages/Analytics/OrganizationAnalytics/OrganizationAnalytics";

export interface AppRoute {
  path: string;
  name?: string;
  element?: ReactNode;
  childRoute?: { path: string; name?: string; element?: ReactNode }[];
}

export const PrivateRoutes: AppRoute[] = [
  {
    path: "/",
    name: "Analytics",
    element: <Analytics />,
  },
  {
    path: "/analytics/organization",
    name: "Organization analytics",
    element: <OrganizationAnalytics />,
  },
  {
    path: "/whitelist",
    name: "Whitelist",
    element: <Whitelist />,
  },
];

export const PublicRoutes: AppRoute[] = [
  {
    path: "/login",
    name: "Login",
    element: <Login />,
  },
];
