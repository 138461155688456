import React, { useState } from "react";

import PopUp from "../../../components/shared/PopUp/PopUp";
import InputField from "../../../components/shared/InputField/InputField";
import Button from "../../../components/shared/Button/Button";
import { Icon } from "../../../components/shared/Icon/Icon";
import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";

import "./AddEmail.scss";

interface AddEmailProps {
  showPopUp: boolean;
  togglePopUp: (value: boolean) => void;
  isLoading: boolean;
  handleAddEmail: (value: string) => void;
}

const AddEmail = (props: AddEmailProps) => {
  const { showPopUp, togglePopUp, handleAddEmail, isLoading } = props;
  const [email, setEmail] = useState<any>();

  const handleChange = (e: any) => {
    setEmail(e.target.value);
  };

  return (
    <PopUp showPopUp={showPopUp} togglePopUp={togglePopUp}>
      <div className="AddEmail">
        <Icon
          onClick={() => togglePopUp(false)}
          className="AddEmail__icon"
          icon="close"
        />
        <h3 className="AddEmail__title">Add new email</h3>

        <div className="AddEmail__input-group-container">
          <InputField
            type="text"
            className="AddEmail__form-control"
            placeholder="Email"
            name="email"
            value={email}
            onBlur={() => {}}
            onChange={(e) => handleChange(e)}
            onIconClick={() => {}}
          />
        </div>

        <Button
          isLoading={isLoading}
          label="Continue"
          onClick={() => handleAddEmail(email)}
          className="Button__primary AddEmail__btn"
          type="button"
          disabled={!email?.includes("@") || email === undefined}
        />
      </div>
    </PopUp>
  );
};

export default AddEmail;
