import { IconDefinition } from "../shared/Icon/Icon.generated";

interface menuItemsTypes {
  id?: string;
  label?: string;
  icon?: IconDefinition;
  path?: string;
  hasChildItems?: boolean;
  childItems?: any;
  isHeader?: boolean;
}

export const menuItems: menuItemsTypes[] = [
  {
    label: "Menu",
    isHeader: true,
  },
  {
    id: "analytics",
    label: "Analytics",
    icon: "analytics",
    path: "/",
  },
  {
    id: "whitelist",
    label: "Whitelist",
    icon: "whitelist",
    path: "/whitelist",
    hasChildItems: false,
  },
];
