import React, { useRef } from "react";

import cs from "classnames";
import { useOutsideClickDetection } from "../../../lib/hooks/useOutsideClickDetection";

import "./PopUp.scss";

interface PopUpPropsType {
  children?: React.ReactNode;
  showPopUp?: boolean;
  position?: "top" | "bottom" | "left" | "right";
  className?: string;
  togglePopUp?: any;
}

const PopUp = (props: PopUpPropsType) => {
  const { children, showPopUp, position, className, togglePopUp } = props;
  const dropDownRef = useRef(null);

  const handleOutSideClick = () => {
    togglePopUp(false);
  };

  useOutsideClickDetection(dropDownRef, handleOutSideClick);

  return (
    <div className={cs("PopUp", showPopUp && "PopUp--show")}>
      <div className={cs("PopUp__wrapper", `PopUp__wrapper--${position}`)}>
        <div className="PopUp__container" ref={dropDownRef}>
          <div className={cs("PopUp__body", className)}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default PopUp;
