import React, { useEffect, useState } from "react";
import * as API from "../../api/Api";
import SearchInput from "../../components/shared/SearchInput/SearchInput";
import { Icon } from "../../components/shared/Icon/Icon";
import { useNavigate } from "react-router-dom";

import "./Analytics.scss";
import { BILLING_TYPES } from "../../constants/organization";

const Analytics = () => {
  const [organizations, setOrganizations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredOrganizations, setFilteredOrganizations] = useState<any>([]);
  const [sortMode, setSortMode] = useState("asc");

  const navigate = useNavigate();

  const getAllOrganizations = () => {
    setIsLoading(true);
    API.getOrganizations()
      .then((res) => {
        setOrganizations(res.data);
        setFilteredOrganizations(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAllOrganizations();
  }, []);

  const handleSearch = (e: any) => {
    e.preventDefault();
    setFilteredOrganizations(
      !e.target.value
        ? organizations
        : organizations.filter((item: any) =>
            item.name.toLowerCase().includes(e.target.value.toLowerCase())
          )
    );
  };

  const sortColumnBy = (mode: string) => {
    if (sortMode === "asc") {
      let sorted = [...filteredOrganizations].sort((a, b) =>
        a[mode]?.toString().toLowerCase() < b[mode]?.toString().toLowerCase()
          ? -1
          : 1
      );
      setFilteredOrganizations(sorted);
    }
    if (sortMode === "desc") {
      let sorted = [...filteredOrganizations].sort((a: any, b: any) => {
        if (
          a[mode]?.toString().toLowerCase() > b[mode]?.toString().toLowerCase()
        ) {
          return -1;
        }
        if (
          b[mode]?.toString().toLowerCase() > a[mode]?.toString().toLowerCase()
        ) {
          return 1;
        }
        return 0;
      });
      setFilteredOrganizations(sorted);
    }
  };
  return (
    <div className="Analytics">
      <div className="Analytics__title">Analytics</div>
      <div className="Analytics__container">
        <div className="Analytics__search-wrapper">
          <SearchInput
            placeholder="Search organizations..."
            onChange={(e) => {
              setSearchValue(e.target.value);
              handleSearch(e);
            }}
          />
        </div>
        {isLoading ? (
          <div className="page-spinner" />
        ) : (
          <>
            {filteredOrganizations.length !== 0 ? (
              <div className="Analytics__grid">
                <div className="Analytics__log-header">
                  <div>
                    <span>Organization Name</span>
                    <Icon
                      icon="arrows-down-up"
                      className="Analytics__header-icon"
                      onClick={() => {
                        sortColumnBy("name");
                        setSortMode(sortMode === "asc" ? "desc" : "asc");
                      }}
                    />
                  </div>
                  <div>
                    <span>Billing Type</span>
                    <Icon
                      icon="arrows-down-up"
                      className="Analytics__header-icon"
                      onClick={() => {
                        sortColumnBy("billing_type");
                        setSortMode(sortMode === "asc" ? "desc" : "asc");
                      }}
                    />
                  </div>
                  <div>
                    <span>Category</span>
                    <Icon
                      icon="arrows-down-up"
                      className="Analytics__header-icon"
                      onClick={() => {
                        sortColumnBy("category");
                        setSortMode(sortMode === "asc" ? "desc" : "asc");
                      }}
                    />
                  </div>
                  <div>
                    <span>Address</span>
                    <Icon
                      icon="arrows-down-up"
                      className="Analytics__header-icon"
                      onClick={() => {
                        sortColumnBy("address");
                        setSortMode(sortMode === "asc" ? "desc" : "asc");
                      }}
                    />
                  </div>
                  <div>
                    <span>Status</span>
                    <Icon
                      icon="arrows-down-up"
                      className="Analytics__header-icon"
                      onClick={() => {
                        sortColumnBy("verified");
                        setSortMode(sortMode === "asc" ? "desc" : "asc");
                      }}
                    />
                  </div>
                </div>
                {filteredOrganizations?.map((item: any, index: number) => (
                  <div
                    className="Analytics__organization"
                    key={index}
                    onClick={() => {
                      localStorage.setItem(
                        "selectedOrganization",
                        JSON.stringify(item)
                      );
                      navigate("/analytics/organization");
                    }}
                  >
                    <div className="Analytics__organization-name-wrapper">
                      <div className="Analytics__image-wrapper">
                        <img
                          src={item.organization_logo_url}
                          alt=""
                          className="Analytics__image"
                        />
                      </div>
                      <div className="Analytics__organization-name">
                        {item.name}
                      </div>
                      <Icon
                        icon="dots-vertical"
                        className="Analytics__organization-icon"
                      />
                    </div>
                    <div className="Analytics__organization-billing-type">
                      {BILLING_TYPES[item?.billing_type ?? 1]}
                    </div>
                    <div className="Analytics__organization-category">
                      {item?.category}
                    </div>
                    <div className="Analytics__organization-address">
                      {item?.address}
                    </div>
                    <div className="Analytics__organization-status">
                      {item?.verified ? (
                        <span className="Analytics__organization-status--true">
                          Verified
                        </span>
                      ) : (
                        <span className="Analytics__organization-status--false">
                          Unverified
                        </span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="Analytics__no-content">
                {" "}
                No organizations found!{" "}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Analytics;
