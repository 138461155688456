import React, { useEffect, useState } from "react";
import * as API from "../../api/Api";
import SearchInput from "../../components/shared/SearchInput/SearchInput";
import { Icon } from "../../components/shared/Icon/Icon";
import Button from "../../components/shared/Button/Button";
import AddEmail from "./AddEmail/AddEmail";
import MessageModal from "../../components/shared/MessageModal/MessageModal";

import "./Whitelist.scss";

const Whitelist = () => {
  const [allowedUsers, setAllowedUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredAllowedUsers, setFilteredAllowedUsers] = useState([]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [sortMode, setSortMode] = useState("asc");

  const getAllAllowedUsers = () => {
    setIsLoading(true);
    API.getAllowedUsers()
      .then((res) => {
        setAllowedUsers(res.data.users);
        setFilteredAllowedUsers(res.data.users);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAllAllowedUsers();
  }, []);

  const handleSearch = (e: any) => {
    e.preventDefault();
    setFilteredAllowedUsers(
      !e.target.value
        ? allowedUsers
        : allowedUsers.filter((item: any) =>
            item?.toLowerCase().includes(e.target.value.toLowerCase())
          )
    );
  };

  const handleAddEmail = (email: string) => {
    setIsLoading(true);
    API.updateAllowedUsers(email)
      .then((res) => {
        getAllAllowedUsers();
        setIsLoading(false);
        setShowPopUp(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };

  const deleteEmail = (email: string) => {
    setIsLoading(true);
    API.deleteAllowedUsers(email)
      .then((res) => {
        getAllAllowedUsers();
        setShowModal(false);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };

  const sortEmails = () => {
    if (sortMode === "asc") {
      let sorted = [...filteredAllowedUsers].sort((a: any, b: any) =>
        a.toLowerCase() < b.toLowerCase() ? -1 : 1
      );
      setFilteredAllowedUsers(sorted);
    }
    if (sortMode === "desc") {
      let sorted = filteredAllowedUsers.sort((a: any, b: any) => {
        if (a.toLowerCase() > b.toLowerCase()) {
          return -1;
        }
        if (b.toLowerCase() > a.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      setFilteredAllowedUsers(sorted);
    }
  };

  return (
    <div className="Whitelist">
      {showModal && (
        <MessageModal
          onCancel={() => setShowModal(false)}
          onAccept={() => deleteEmail(selectedEmail)}
          toggle={() => setShowModal(!showModal)}
          typeOfModal="danger"
          isOpen={showModal}
          title="Are you sure you want to permanently delete this email?"
          description="Once deleted, all data will be lost."
        />
      )}
      <div className="Whitelist__header">
        <div className="Whitelist__title">Whitelist</div>
        <Button
          onClick={() => setShowPopUp(true)}
          label="Add new email"
          className="Whitelist__header-button"
        />
      </div>
      <div className="Whitelist__container">
        <div className="Whitelist__search-wrapper">
          <SearchInput
            placeholder="Search email address..."
            onChange={(e) => {
              setSearchValue(e.target.value);
              handleSearch(e);
            }}
          />
        </div>
        {isLoading ? (
          <div className="page-spinner" />
        ) : (
          <>
            {filteredAllowedUsers.length !== 0 ? (
              <div className="Whitelist__grid">
                <div className="Whitelist__log-header">
                  <div>
                    <span>Email Address</span>
                    <Icon
                      icon="arrows-down-up"
                      className="Whitelist__header-icon"
                      onClick={() => {
                        sortEmails();
                        if (sortMode === "asc") {
                          setSortMode("desc");
                        } else {
                          setSortMode("asc");
                        }
                      }}
                    />
                  </div>
                </div>
                {filteredAllowedUsers?.map((item: any, index) => (
                  <div
                    className="Whitelist__item"
                    key={index}
                    onClick={() => setSelectedEmail(item)}
                  >
                    <div className="Whitelist__item-name">{item}</div>
                    <Icon
                      icon="delete"
                      className="Whitelist__item-icon"
                      onClick={() => setShowModal(true)}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className="Whitelist__no-content"> No email found! </div>
            )}
          </>
        )}
      </div>
      {showPopUp && (
        <AddEmail
          showPopUp={showPopUp}
          togglePopUp={() => setShowPopUp(!showPopUp)}
          handleAddEmail={handleAddEmail}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

export default Whitelist;
