import React, { useRef, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { useSidebarContext } from "../../lib/context/SidebarContext/SidebarContext";
import { menuItems } from "./menuItems";
import BouncerLogo from "../../../src/assets/images/bouncer.svg";
import BouncerIcon from "../../../src/assets/images/bouncer-icon.svg";
import cs from "classnames";
import { useOutsideClickDetection } from "../../lib/hooks/useOutsideClickDetection";
import { useResponsiveDimensions } from "../../lib/hooks/useResponsiveDimensions";
import LevelOne from "./LevelOne/LevelOne";
import { Icon } from "../shared/Icon/Icon";

import "./Sidebar.scss";

const Sidebar = () => {
  const [items, setItems] = useState<any>(menuItems);
  const [showScroll, setShowScroll] = useState(false);
  const isSidebarOpen = useSidebarContext().isSidebarOpen;
  const isMobile = useResponsiveDimensions().isMobile;
  const changeSidebarVisibility = useSidebarContext().changeSidebarVisibility;
  const navigate = useNavigate();
  const location = useLocation();

  const sidebarRef = useRef(null);

  useOutsideClickDetection(
    isMobile && isSidebarOpen && sidebarRef,
    changeSidebarVisibility
  );

  const url = location.pathname;
  const splitUrl = url.split("/");

  return (
    <div
      ref={sidebarRef}
      className={cs(
        "Sidebar",
        isSidebarOpen && "Sidebar__open",
        isMobile && "Sidebar__mobile",
        isSidebarOpen && "Sidebar__mobile--open"
      )}
    >
      <div
        className={cs(
          "Sidebar__logo-container",
          isSidebarOpen && "Sidebar__logo-container--open"
        )}
      >
        <img
          src={isSidebarOpen ? BouncerLogo : BouncerIcon}
          className={cs("Sidebar__logo")}
          onClick={() => navigate("/")}
          alt=""
        />
        {!isMobile && (
          <div
            className={cs(
              "Sidebar__icon-container",
              isSidebarOpen && "Sidebar__icon-container--open"
            )}
          >
            <Icon
              onClick={() => changeSidebarVisibility()}
              className="Sidebar__icon"
              icon="expand"
            />
          </div>
        )}
      </div>
      <div
        onMouseEnter={() => {
          setShowScroll(true);
        }}
        onMouseLeave={() => {
          setShowScroll(false);
        }}
        className={cs(
          "Sidebar__content",
          isSidebarOpen && "Sidebar__content--open",
          isSidebarOpen && showScroll && "Sidebar__content-scroll",
          isSidebarOpen && !showScroll && "Sidebar__content-scroll-hide"
        )}
      >
        {menuItems.map((item, index) => (
          <LevelOne
            key={index}
            isOpen={
              items.find((i: any) => i.label === item.label).open ||
              item.id === splitUrl[1]
            }
            id={item.id}
            icon={item.icon}
            label={item.label}
            path={item.path}
            onClick={() => {
              navigate(`${item.path}`);
              if (isMobile) {
                changeSidebarVisibility();
              }
            }}
            isHeader={item.isHeader}
          />
        ))}
      </div>
    </div>
  );
};
export default Sidebar;
