import { useContext, createContext } from "react";

export interface AuthContextValuesType {
  userError: boolean;
  isAuthenticated: boolean;
  handleLogin: (value: any) => void;
  logOut: () => void;
  isLoading: boolean;
  message: any;
  user: any;
}

const AuthContextValues: AuthContextValuesType = {
  userError: false,
  isAuthenticated: false,
  isLoading: false,
  handleLogin: (value: any) => {},
  logOut: () => {},
  message: {},
  user: "",
};

export const AuthContext =
  createContext<AuthContextValuesType>(AuthContextValues);
export const useAuthContext = () => useContext(AuthContext);
