import React, { useState } from "react";

import { useLoginFormik } from "./useLoginFormik";
import Button from "../../../components/shared/Button/Button";
import InputField from "../../../components/shared/InputField/InputField";
import * as API from "../../../api/Api";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";

import "./LoginForm.scss";

const LoginForm = () => {
  const authCtx = useAuthContext();

  const formik = useLoginFormik({
    onSubmit: (values: any) => {
      const data = {
        email: values.email,
      };
      authCtx.handleLogin(data);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="LoginForm__input-group-container">
        <InputField
          type="text"
          className="LoginForm__form-control"
          placeholder="Email address"
          name="email"
          value={formik.values.email}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          onIconClick={() => {}}
        />
        {formik.errors.email && formik.touched.email && (
          <div className="LoginForm__invalid-feedback">
            Please enter your email
          </div>
        )}
      </div>

      <Button
        isLoading={authCtx.isLoading}
        label="Log in with email"
        onClick={() => {}}
        className="Button__primary LoginForm__btn"
        type="submit"
        disabled={formik.values.email.length === 0}
      />
    </form>
  );
};

export default LoginForm;
