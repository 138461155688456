import React, { useState } from "react";

import cs from "classnames";

import { useSidebarContext } from "../../lib/context/SidebarContext/SidebarContext";
import { useResponsiveDimensions } from "../../lib/hooks/useResponsiveDimensions";
import bell from "../../assets/images/bell.svg";

import HeaderProfile from "./HeaderProfile/HeaderProfile";
import HamburgerButton from "./HamburgerButton/HamburgerButton";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";

import "./Header.scss";

const Header = () => {
  const [showShadow, setShowShadow] = useState(false);
  const isSidebarOpen = useSidebarContext().isSidebarOpen;
  const isMobile = useResponsiveDimensions().isMobile;
  const authCtx = useAuthContext();

  window.addEventListener("scroll", () => {
    const lastKnownScrollPosition = window.scrollY;

    if (lastKnownScrollPosition > 40) {
      setShowShadow(true);
    } else {
      setShowShadow(false);
    }
  });

  return (
    <div
      className={cs(
        "Header",
        showShadow && "Header__shadow",
        isSidebarOpen && "Header__open",
        isMobile && "Header__mobile"
      )}
    >
      <div className="Header__left">{isMobile && <HamburgerButton />}</div>
      <div className="Header__right">
        <HeaderProfile />{" "}
      </div>
    </div>
  );
};

export default Header;
